import { get, getOr } from 'lodash/fp';
import { formatPhone } from '@/utils/format';
import { mapCustomFieldsToFormInitialValues } from '@/utils/customFields';
import { ICustomField } from '@/types/customField';
import { CUSTOM_FIELD_TYPES } from '@/constants';
import { ICustomer } from '@/types/customer';

const initialAddressFields = {
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
}

export const companyProfileInitialValues = (customerData: ICustomer) => {

  const providerCompany = getOr(null, 'providerCompany', customerData);
  const customFields = getOr([], 'customFields', providerCompany);
  const customFieldAnswers = getOr([], 'customFieldAnswers', customerData);
  const additionalQuestions: ICustomField[] = customFields.filter(customField => customField.type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION);
  console.log(customerData, 'customerData');
  const output = {
    name: get('seekerCompany.name', customerData) || '',
    otherNames: '',
    type: get('seekerCompany.type', customerData) || '',
    address: get('seekerCompany.address', customerData) || initialAddressFields,
    shippingAddress: get('latestApplication.address', customerData) || initialAddressFields,
    billingAddress: get('latestApplication.address', customerData) || initialAddressFields,
    website: get('seekerCompany.website', customerData) || '',
    established: get('seekerCompany.established', customerData) ? `${get('seekerCompany.established', customerData)}` : '',
    phoneNumber: get('seekerCompany.phoneNumber', customerData) ? formatPhone(get('seekerCompany.phoneNumber', customerData)) : '',
    description: get('seekerCompany.description', customerData) || '',
    additionalQuestions: mapCustomFieldsToFormInitialValues(additionalQuestions, customFieldAnswers),
  }

  console.log({output});
  return output;
}

