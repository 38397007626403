import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { GET_DASHBOARD_OVERVIEW } from '@/graphql/queries/getDashboardOverview';

import { getRoles } from '@/utils/getRoles';

import { ICompany } from '@/types/company';
import { IGetDashboardOverviewData } from '@/types/querries';

import { Navigation } from '@/containers/AppContainer/Navigation';
import { LogoutModal } from '@/modals/LogoutModal';

import { NAV_ITEMS } from '@/constants';
import { INavOptions } from '@/types/navOptions';

import {
  StyledContainer,
  Main,
  Content,
} from './styled';
import { useNavOptions } from './useNavOptions';

interface IProps {
  children: React.ReactNode,
}

export const AppContainer = ({ children }: IProps) => {
  const [roles, setRoles] = useState<string[]>([]);
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  // const NAV_OPTIONS = [
  //   [
  //     { title: NAV_ITEMS.CUSTOMERS, path: '/dashboard/customers' },
  //     { title: NAV_ITEMS.APPLICATIONS, path: '/dashboard/requests' },
  //     { title: NAV_ITEMS.REFERENCES, path: '/dashboard/references' },
  //   ],
  //   [
  //     { title: NAV_ITEMS.TEMPLATE, path: '/dashboard/application' },
  //     { title: NAV_ITEMS.SETTINGS, path: '/settings/' },
  //     { title: NAV_ITEMS.HELP, onClick: () => window.open('https://help.nectarinecredit.com') },
  //     { title: NAV_ITEMS.LOGOUT, onClick: () => setLogoutModalOpen(true) },
  //   ],
  // ];

  const NAVIGATIONS: INavOptions = [
    [
      {
        title: NAV_ITEMS.APPLICANTS, path: '/dashboard/requests'
      },
      {
        title: NAV_ITEMS.APPLICATIONS,
        children: [
          { title: NAV_ITEMS.PENDING_APPROVAL, path: '/dashboard/customers/pending-approval' },
          { title: NAV_ITEMS.IN_PROGRESS, path: '/dashboard/customers/in-progress' },
          { title: NAV_ITEMS.ARCHIVED, path: '/dashboard/customers/archived' },
        ],
      },
      {
        title: NAV_ITEMS.CUSTOMERS,
        children: [
          // { title: NAV_ITEMS.PENDING_REVIEW, path: '/dashboard/customers/pending-review' },
          { title: NAV_ITEMS.APPROVED, path: '/dashboard/customers/approved' },
          // { title: NAV_ITEMS.IMPORTED, path: '/dashboard/customers/imported' },
        ],
      },
      {
        title: NAV_ITEMS.REFERENCES, path: '/dashboard/references'
      }
    ],
    [
      { title: NAV_ITEMS.TEMPLATE, path: '/dashboard/application' },
      { title: NAV_ITEMS.SETTINGS, path: '/settings/' },
      { title: NAV_ITEMS.LOGOUT, onClick: () => setLogoutModalOpen(true) },
    ],
  ]

  const handleDashboardRequest = (company: ICompany) => {
    setRoles(getRoles(company));
  };
  
  const navOptions = useNavOptions(roles, NAVIGATIONS);
  
  const { data } = useQuery<IGetDashboardOverviewData>(GET_DASHBOARD_OVERVIEW);

  useEffect(() => {
    if (data) {
      handleDashboardRequest(data.currentCompany)
    }
  }, [data])

  return (
    <StyledContainer>
      {roles.length ? (
        <Navigation options={navOptions} />
      ) : null}
      <Main>
        <Content>
          {children}
        </Content>
      </Main>
      <LogoutModal isOpen={isLogoutModalOpen} setIsOpen={setLogoutModalOpen} />
    </StyledContainer>
  );
};
