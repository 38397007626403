import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'

import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_COMPANY } from '@/graphql/queries/getCurrentCompany';
import { GET_CURRENT_USER } from '@/graphql/queries/getCurrentUser';

import { VisitorContext } from '@/providers/VisitorContext';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { CurrentUserContext } from '@/providers/CurrentUser';

import { AppContainer } from '@/containers/AppContainer';

import { PrivateRoute } from '@/routes/Routes';

import { CustomersPendingReviewDashboard } from '@/views/CustomersPendingReviewDashboard';
import { CustomersApprovedDashboard } from '@/views/CustomersApprovedDashboard';
import { CustomersImportedDashboard } from '@/views/CustomersImportedDashboard';
import { CustomersPendingApprovalDashboard } from '@/views/CustomersPendingApprovalDashboard';
import { CustomersInProgressDashboard } from '@/views/CustomersInProgressDashboard';
import { CustomersArchivedDashboard } from '@/views/CustomersArchivedDashboard';
import { ApplicantDashboard } from '@/views/ApplicantDashboard';
import { ApplicationTemplate } from '@/views/ApplicationTemplate';
import { CustomerPage } from '@/views/CustomerPage';
import { VendorReferencePage } from '@/views/VendorReferencePage';
import { ApplicantPage } from '@/views/ApplicantPage';
import { VendorReferencesDashboard } from '@/views/VendorReferencesDashboard';
import { EversignTestPage } from '@/views/_test/EversignTestPage';

import { IncompleteCreditApplicationModal } from '@/modals/IncompleteCreditApplicationModal';
import DefaultDashboardRoute from '@/routes/DefaultDashboardRoute';

import { SettingsApp } from './SettingsApp';

export const ProductApp = () => {
  const [visitorDataState, setVisitorDataState] = useState<ExtendedGetResult | null>(null);

  const {
    isLoading: visitorLoading,
    data: visitorData,
  } = useVisitorData({ extendedResult: true }, { immediate: true });

  useEffect(() => {
    if (!visitorLoading && visitorData) {
      setVisitorDataState(visitorData)
    }
  }, [visitorData, visitorLoading]);

  const {
    data: currentCompanyData,
    loading: currentCompanyLoading,
    refetch: refetchCurrentCompany
  } = useQuery(GET_CURRENT_COMPANY, {fetchPolicy: 'no-cache'});

  const {
    data: currentUserData,
    loading: currentUserLoading,
    refetch: refetchCurrentUser
  } = useQuery(GET_CURRENT_USER);

  return <VisitorContext.Provider value={ visitorDataState }>
    <CurrentUserContext.Provider value={
      {
        ...currentUserData,
        currentUserLoading,
        refetchCurrentUser
      }
    }>
      <CurrentCompanyContext.Provider value={
        {
          ...currentCompanyData,
          currentCompanyLoading,
          refetchCurrentCompany
        }
      }>
        <AppContainer>
          <Switch>
            <Route exact path='/dev/' component={EversignTestPage} />
            <Route exact path='/dashboard/' component={DefaultDashboardRoute} />
            <Route exact path='/dashboard/customers' component={DefaultDashboardRoute} />
            <PrivateRoute path='/dashboard/customers/pending-review' exact component={CustomersPendingReviewDashboard} />
            <PrivateRoute path='/dashboard/customers/pending-approval' exact component={CustomersPendingApprovalDashboard} />
            <PrivateRoute path='/dashboard/customers/in-progress' exact component={CustomersInProgressDashboard} />
            <PrivateRoute path='/dashboard/customers/archived' exact component={CustomersArchivedDashboard} />
            <PrivateRoute path='/dashboard/customers/approved' exact component={CustomersApprovedDashboard} />
            <PrivateRoute path='/dashboard/customers/imported' exact component={CustomersImportedDashboard} />
            <PrivateRoute path='/dashboard/customers/referral/:referralCode' exact component={VendorReferencePage} />
            <PrivateRoute path='/dashboard/customer/:customerId' exact component={CustomerPage} />
            <PrivateRoute path='/dashboard/requests' exact component={ApplicantDashboard} />
            <PrivateRoute path='/dashboard/requests/:customerId' exact component={ApplicantPage} />
            <PrivateRoute path='/dashboard/references' exact component={VendorReferencesDashboard} />
            <PrivateRoute path='/dashboard/application' component={ApplicationTemplate} />
            <PrivateRoute path='/settings' component={SettingsApp} />
          </Switch>
          <IncompleteCreditApplicationModal />
        </AppContainer>
      </CurrentCompanyContext.Provider>
    </CurrentUserContext.Provider>
  </VisitorContext.Provider>
};
