import React from 'react';
import { H2 } from '@/components/designSystem/Typography';
import { SideTitle } from '../SideTitle';
import { StyledSlideActionContainer } from '../styled';
import { StyledMessageWrapper } from './styled';
import { Button } from '@/components/designSystem/buttons';

export const Review = () => {
  return (
    <StyledSlideActionContainer>
      <SideTitle title='Review' />
      <StyledMessageWrapper>
        <H2>
        To upgrade and add our Review Module, please schedule a call with your Nectarine Credit account executive.
        </H2>
        <Button href='https://calendly.com/alex-1257' target='_blank' primary wide>Schedule a call</Button>
      </StyledMessageWrapper>
    </StyledSlideActionContainer>
  )};
