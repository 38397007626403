import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { PROVIDER_UPDATE_APPLICATION } from '@/graphql/mutations/providerUpdateApplication';
import { CREATE_CHANGE_REQUEST } from '@/graphql/mutations/createChangeRequest';

import { Formik } from 'formik';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { includes } from 'lodash';
import { get } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { IApplication } from '@/types/application';
import { ICustomer } from '@/types/customer';

import { CREDIT_APPLICANT_STATUSES } from '@/constants';

import { SideTitle } from '../SideTitle';
import { actionsFormValidation } from './validation';
import { ActionsForm } from './ActionsForm';
import { ConfirmationModal } from '../ConfirmationModal';
import { StyledSlideActionContainer } from '../styled';
import { getActionsInitialValues } from './initialValues';

interface IChangesRequestedSections {
  company_info: boolean,
  company_contacts: boolean,
  bank_info: boolean,
  vendors: boolean,
  customizable: boolean,
}

interface IFields {
  status: string,
  providerComment: string,
  changesRequestedSections: IChangesRequestedSections,
}

interface IProps {
  customerData: ICustomer,
  customerId: string,
  application: IApplication,
  seekerDenialAlert: boolean,
  seekerApprovalAlert: boolean,
  canSeeEditControl: boolean,
  creditTermsOptions: string[],
  refetchCustomerData: () => void,
  closeDrawer: () => void,
}

export const RequestChanges = ({
  customerData,
  customerId,
  application,
  canSeeEditControl,
  refetchCustomerData,
  closeDrawer,
}: IProps) => {
  const [updateApplication, { loading }] = useMutation(PROVIDER_UPDATE_APPLICATION);
  const [createChangeRequest, { loading: changeRequestLoading} ] = useMutation(CREATE_CHANGE_REQUEST);

  const applicationId = get('id', application);
  const applicationStatus = get('status', application);
  const [selectedStatus, setSelectedStatus] = useState(applicationStatus);

  const initialValues = getActionsInitialValues(application);
  const validation = actionsFormValidation();

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const changeRequestSectionsToList = (changeRequestSections: IChangesRequestedSections) => {
    return Object.entries(changeRequestSections).filter((section) => section[1]).map((section) => section[0]);
  }

  const onSubmit = async ({
    status, providerComment, changesRequestedSections
  }: IFields) => {
    const changesRequestedSectionsList = Object.entries(changesRequestedSections).filter((section) => section[1]).map((section) => section[0])
    const variables = {
      applicationId,
      applicationAttrs: {
        status,
        providerComment,
        changeRequestSections: changesRequestedSectionsList,
      },
    };

    updateApplication({ variables })
      .then(() => {
        refetchCustomerData();
      })
      .catch((error) => {
        showToast({
          title: 'Error!',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      });
  };

  const submitOrConfirm = (values: any) => {
    const toConfirm = values.providerComment
      || (includes([CREDIT_APPLICANT_STATUSES.CHANGES_REQUESTED], values.status));
    if (toConfirm) {
      setConfirmationModalOpen(true);
    } else {
      onSubmit(values);
    }
  };

  const onChangeRequestSubmit = async (values: any) => {
    const variables = {
      customerId: get('id', customerData),
      changeRequestAttrs: {
        sections: changeRequestSectionsToList(get('changesRequestedSections', values)),
        comment: get('providerComment', values),
        source: "external"
      }
    };
    createChangeRequest({variables})
    .then(() => {
      showToast({
        title: 'Success!',
        description: 'Company settings Updated Successfully.',
        type: toast.TYPE.SUCCESS,
      });
      refetchCustomerData();
    })
  }

  return (
    <StyledSlideActionContainer>
      <SideTitle title='Request Changes' />
      <Formik
        onSubmit={(values) => handleSubmitWrapper(values, submitOrConfirm)}
        initialValues={initialValues}
        validationSchema={validation}
        validateOnChange
        enableReinitialize
      >
        {({ values }) => (
          <>
            <ActionsForm
              customerData={customerData}
              customerId={customerId}
              loading={loading}
              canSeeEditControl={canSeeEditControl}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              setIsOpen={setConfirmationModalOpen}
              currentStatus={applicationStatus}
              selectedStatus={selectedStatus}
              onConfirm={() => {
                onChangeRequestSubmit(values);
                setConfirmationModalOpen(false);
                closeDrawer();
              }}
            />
          </>
        )}
      </Formik>
    </StyledSlideActionContainer>
  )};
