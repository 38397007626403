import { CUSTOM_FIELD_TYPES } from '@/constants';
import { IChangeRequest } from './types';
import { ICustomField } from '@/types/customField';
import { get, getOr } from 'lodash/fp';
import { AgreementStep } from './steps/AgreementStep';
import { CompanyContactsStep } from './steps/CompanyContactsStep';

import { CompanyProfileStep } from './steps/CompanyProfileStep';
import { FinancialHealthStep } from './steps/FinancialHealthStep';
import { OtherInfoStep } from './steps/OtherInfoStep';
import { VendorsStep } from './steps/VendorsStep';

const STEPS_INFO = {
  COMPANY_PROFILE: {
    path: 'companyProfile',
    label: 'Company Profile',
    changesRequestedSection: 'company_info'
  },
  COMPANY_CONTACTS: {
    path: 'stakeholder',
    label: 'Company Contacts',
    changesRequestedSection: 'company_contacts'
  },
  FINANCIAL_HEALTH: {
    path: 'bank',
    label: 'Financial Health',
    changesRequestedSection: 'bank_info'
  },
  VENDORS: {
    path: 'vendors',
    label: 'Vendors',
    changesRequestedSection: 'vendors'
  },
  OTHER_INFO: {
    path: 'others',
    label: 'Other Info',
    changesRequestedSection: 'customizable'
  },
  AGREEMENT: {
    path: 'agreement',
    label: 'Agreement',
    changesRequestedSection: 'agreement'
  },
}

interface IGetStepsArgs {
  changeRequest: IChangeRequest,
  fullApplication: boolean
}

export const getSteps = ({
  changeRequest,
  fullApplication,
}: IGetStepsArgs) => {
  const customerData = get('customer', changeRequest);
  const providerCompany = getOr(null, 'providerCompany', customerData);
  const providerCompanySettings = getOr(null, 'settings', providerCompany);
  const showCreditTerms = getOr(false, 'attachCreditOptions', providerCompanySettings);
  const requireEinFederalTaxNumber = getOr(false, 'requireEinFederalTaxNumber', providerCompanySettings);
  const creditTermsOptions = getOr([], 'creditTerms', providerCompany);

  const customFields = getOr([], 'customFields', providerCompany);
  const additionalQuestions: ICustomField[] = customFields.filter(customField => customField.type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION);
  const customContactFields: ICustomField[] = customFields.filter(customField => customField.type === CUSTOM_FIELD_TYPES.CONTACT);
  const customFieldsForOtherInfoStep: ICustomField[] = customFields.filter(
    customField => (customField.type !== CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION) && (customField.type !== CUSTOM_FIELD_TYPES.CONTACT));

  const automaticBankVerificationEnabled = getOr(false, 'settings.autoBav', customerData);
  const requireBankInfo = getOr(false, 'settings.requireBankInfo', customerData);

  const changesRequestedSections = getOr([], 'sections', changeRequest);

  const stepsWithoutProps = [
    ...changesRequestedSections.find((section: string) => section === STEPS_INFO.COMPANY_PROFILE.changesRequestedSection) || fullApplication ? [{
      path: STEPS_INFO.COMPANY_PROFILE.path,
      label: STEPS_INFO.COMPANY_PROFILE.label,
      Component: CompanyProfileStep,
    }] : [],
    ...changesRequestedSections.find((section: string) => section === STEPS_INFO.COMPANY_CONTACTS.changesRequestedSection) || fullApplication ? [{
      path: STEPS_INFO.COMPANY_CONTACTS.path,
      label: STEPS_INFO.COMPANY_CONTACTS.label,
      Component: CompanyContactsStep,
    }] : [],
    ...changesRequestedSections.find((section: string) => section === STEPS_INFO.FINANCIAL_HEALTH.changesRequestedSection) || fullApplication ? [{
      path: STEPS_INFO.FINANCIAL_HEALTH.path,
      label: STEPS_INFO.FINANCIAL_HEALTH.label,
      Component: FinancialHealthStep,
    }] : [],
    ...changesRequestedSections.find((section: string) => section === STEPS_INFO.VENDORS.changesRequestedSection) || fullApplication ? [{
      path: STEPS_INFO.VENDORS.path,
      label: STEPS_INFO.VENDORS.label,
      Component: VendorsStep,
    }] : [],
    ...changesRequestedSections.find((section: string) => section === STEPS_INFO.OTHER_INFO.changesRequestedSection) || fullApplication ? [{
      path: STEPS_INFO.OTHER_INFO.path,
      label: STEPS_INFO.OTHER_INFO.label,
      Component: OtherInfoStep,
    }] : [],
    {
      path: STEPS_INFO.AGREEMENT.path,
      label: STEPS_INFO.AGREEMENT.label,
      Component: AgreementStep,
    },
  ];

  const getStepProps = (stepPath: string, nextStepPath: string | null) => {
    switch (stepPath) {
      case (STEPS_INFO.COMPANY_PROFILE.path):
        return {
          additionalQuestions,
          nextStepPath,
        };
      case (STEPS_INFO.COMPANY_CONTACTS.path):
        return {
          providerCompanySettings,
          customContactFields,
          nextStepPath
        };
      case (STEPS_INFO.FINANCIAL_HEALTH.path):
        return {
          providerCompanySettings,
          automaticBankVerificationEnabled,
          requireBankInfo,
          nextStepPath
        };
      case (STEPS_INFO.VENDORS.path):
        return {
          nextStepPath,
        };
      case (STEPS_INFO.OTHER_INFO.path):
        return {
          providerCompanySettings,
          customFields: customFieldsForOtherInfoStep,
          showCreditTerms,
          creditTermsOptions,
          requireEinFederalTaxNumber,
          nextStepPath,
        };
      case (STEPS_INFO.AGREEMENT.path):
        return {
          providerCompany,
        };
      default: 
        return {};
    }
  }

  return stepsWithoutProps.map((step, index) => ({
    ...step,
    stepProps: getStepProps(step.path, stepsWithoutProps[index + 1]?.path || null),
  }))
}
