import gql from 'graphql-tag';

export const DASHBOARD_CUSTOMERS = gql`
  query DashboardCustomers($status: String!) {
    currentCompany {
      id
    }

    listAdditionalQuestions {
      id
      label
    }

    listCustomers(status: $status) {
      id
      seekerCompany {
        id
        name
      }
      latestApplication {
        status
        providerDisplayStatus
        creditLimit
        creditTerms
        assignedTo {
          id
          name
        }
        dateApproved
        lastUpdatedBy {
          id
          name
        }
      }
      customFieldAnswers {
        id
        text
        customField {
          id
          type
          label
          description
          required
          allowMultipleUploads
          options
          contact {
            showName
            nameRequired
            showEmail
            emailRequired
            showPhone
            phoneRequired
            showExtension
            extensionRequired
          }
          yesToggle {
            type
            allowMultipleUploads
          }
          noToggle {
            type
            allowMultipleUploads
          }
        }
      }
    }

    pendingCustomerInvites(status: $status) {
      id
      toCompanyName
      toEmail
      type
      from {
        id
        name
      }
      referralCode
      sent
    }
  }
`;
