import React, {useState} from 'react';

import { ReactComponent as Avatar } from '@/assets/avatar.svg';
import { ReactComponent as Page } from '@/assets/page.svg';
import { getOr } from 'lodash/fp';

import { Flex, FlexColumn } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';
import { H3, H4, BodyText } from '@/components/designSystem/Typography';
import { formatDateToTimeAgo } from '@/utils/date';

import {
  StyledActivityContainer,
  StyledActivityLine,
  StyledTypeContainer,
  StyledIconWrapper,
  StyledIconContainer,
  StyledMessageWrapper,
  StyledTimeWrapper,
  StyledBox,
  StyledAgreementBox,
  StyledActivityList,
  StyledUpdateGrid,
  StyledBodyTextStrikeThrough,
  StyledChangeBox,
} from './styled';

interface IActivity {
  type: 'email' | 'application_started' | 'agreement_signed' | 'application_updated' | 'application_status_updated';
  actor: any,
  data: any,
  insertedAt: string
}

interface IProps {
  activities: any[],
}

interface IActivityItemProps {
  item: IActivity,
  index: number
}

const DiffField = (({diff} : any) => (
  <div>
    {diff.field && <div style={{fontWeight: 700}}>{diff.field}:</div>}
    {diff.removed && <div style={{textDecoration: "line-through", color: "rgb(156 163 175)"}}>{diff.removed}</div>}
    <div>{diff.added}</div>
  </div>

));

const ActivityItem = (props: IActivityItemProps) => {
  const {item} = props;

  const ActivityApplicationStarted = ({_item}: any) =>  <></>

  const ActivityAgreementSigned = ({_item}: any) => {
    return(
      <StyledAgreementBox>
        <H3 bold>Agreement signed</H3>
        <Button secondary>Preview</Button>
      </StyledAgreementBox>
    )
  } 

  const ActivityApplicationUpdated = ({item}: any) => {
    const diff = item.data.diff;
    return (
      <StyledBox>
        {diff.eq && diff.eq.map((item : any) => <DiffField diff={item} />)}
        <DiffField diff={diff} />
      </StyledBox>
    )
  }

  const ActivityApplicationStatusUpdated = ({item}: any) => {
    const data = item.data;
    const status = getOr('default', 'status', data);
    const map : any = {
      'approved': (
        <StyledBox style={{width: "25%"}}>
          <table style={{width: "100%"}}>
          <tr>
            <td style={{fontWeight: "bold"}}>Status:</td>
            <td>approved</td>
          </tr>
          {data.credit_limit && (
            <tr>
              <td style={{fontWeight: "bold"}}>Credit limit:</td>
              <td>{data.credit_limit}</td>
            </tr>
          )}
          {data.credit_terms && (
            <tr>
              <td style={{fontWeight: "bold"}}>Credit term:</td>
              <td>{data.credit_terms}</td>
            </tr>
          )}
          </table>
          {data.comment && <div style={{paddingTop: "1rem"}}>{data.comment}</div>}
        </StyledBox>
      ),
      'default': (
        <StyledBox>
          <span><strong>Status:</strong> {status}</span>

          {data.comment && <div style={{paddingTop: "1rem"}}>{data.comment}</div>}
        </StyledBox>
      )
    }
    return getOr(map['default'], status, map);
  }

  const ProviderAddInternalComment = ({item}: any) => {
    return (
      <StyledBox>
        {item.data.comment}
      </StyledBox>
    )
  }

  const ActivityEmail = ({item}: any) => {
    return (
      <StyledBox>
        Mailer sent to {item.data.to}
      </StyledBox>
    )
  }

  const mappings: {[index: string]: any} = {
    application_started: {
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>started an application</b></span>,
      component: <ActivityApplicationStarted item={item} />
    },
    agreement_signed: {
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>signed and submitted an application</b></span>,
      component: <ActivityAgreementSigned item={item} />
    },
    application_updated: {
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>submitted updated information</b></span>,
      component: <ActivityApplicationUpdated item={item} />
    },
    application_status_updated: {
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>updated application status</b></span>,
      component: <ActivityApplicationStatusUpdated item={item} />
    },
    provider_add_internal_comment: {
      message: <span>{item.actor.user_name} from {item.actor.company_name}</span>,
      component: <ProviderAddInternalComment item={item} />
    },
    email: {
      message: <span>{item.actor.user_name} sent a {item.data.action_identfier} email to {item.data.to}</span>,
      component: <ActivityEmail item={item} />
    }
  }
  return (
    <Flex direction='column' style={{paddingBottom: '1.5rem' }}>
      <StyledActivityLine />
      <Flex direction='row' align='flex-start' position='relative'>
        <StyledIconWrapper>
          <StyledIconContainer>
            <Avatar />
          </StyledIconContainer>
        </StyledIconWrapper>
        <StyledMessageWrapper>
          <Flex direction='row' justify='space-between' align='center'>
            <H4>{getOr({message: null}, item.type, mappings).message}</H4>
            <StyledTimeWrapper>{formatDateToTimeAgo(item.insertedAt)}</StyledTimeWrapper>
          </Flex>
          { getOr({component: false}, item.type, mappings).component }
        </StyledMessageWrapper>
      </Flex>
    </Flex>
  )
}

export const Activity = ({ activities }: IProps) => {
  return (
    <StyledActivityList>
      {activities.map((activityItem : any, activityItemIdx : any) => (
        <li key={activityItemIdx}>
          <StyledActivityContainer>
            <ActivityItem item={activityItem} index={activityItemIdx} />
          </StyledActivityContainer>
        </li>
      ))}
    </StyledActivityList>
  )};
