import { countries } from 'countries-list';

import { mapWithKey } from './utils/object';

import { ICountry } from './types/country';
import { ILabelValue } from './types/field';

export const COUNTRIES = mapWithKey(
  ({ name }: ICountry, countrycode: string) => ({ label: name, value: countrycode }),
  countries,
);

const SORTING_VALUES = ['US', 'CA'];

export const SORTED_COUNTRIES = [
  ...COUNTRIES.filter((country: ILabelValue) => SORTING_VALUES.includes(country.value)),
  ...COUNTRIES.filter((country: ILabelValue) => !SORTING_VALUES.includes(country.value)),
];

export const USER_ROLES = [
  { label: 'Read Only', value: 'read_only' },
  { label: 'Analyst', value: 'analyst' },
  { label: 'Credit Manager', value: 'manager' },
  { label: 'Controller', value: 'controller' },
]

export const CREDIT_APPLICANT_STATUSES = {
  INVITED: 'sent',
  CREATED: 'created',
  IN_PROGRESS: 'in_progress',
  PENDING_APPROVAL: 'pending_approval',
  CHANGES_REQUESTED: 'changes_requested',
  APPROVED: 'approved',
  DENIED: 'denied',
  AGREEMENT_PROCESSING: 'agreement_processing',
  AGREEMENT_VIEWED: 'agreement_viewed',
}

export const CREDIT_TERMS = [
  { label: 'Net 30', value: 'Net 30' },
  { label: 'Net 60', value: 'Net 60' },
  { label: '2% 10, Net 30', value: '2% 10, Net 30' },
  { label: '2% 10, Net 60', value: '2% 10, Net 60' },
];

export const CREDIT_STATUSES_OPTIONS = [
  { label: 'Approved', value: 'approved' },
  // { label: 'Changes Requested', value: 'changes_requested' },
  { label: 'Denied', value: 'denied' },
  { label: 'Pending Approval', value: 'pending_approval' },
  // {label: "Created", value: "created"},
];

export const MANUAL_CUSTOMER_CREDIT_STATUSES_OPTIONS = [
  { label: 'Approved', value: 'approved' },
  { label: 'Denied', value: 'denied' },
  { label: 'Pending Approval', value: 'pending_approval' }
];

export const COMPANY_TYPES = [
  { label: 'Corporation (C Corporation)', value: 'C-CORP' },
  { label: 'Limited Liability Company (LLC)', value: 'LLC' },
  { label: 'S Corporation (Subchapter S Corporation)', value: 'S-CORP' },
  { label: 'Limited Partnership (LP)', value: 'LP' },
  { label: 'Limited Liability Partnership (LLP)', value: 'LLP' },
  { label: 'Sole Proprietorship/Individual', value: 'SP' },
];
export const NUMBER_OF_COMPANY_TYPES = 6;

export const CUSTOM_FIELD_TYPES = {
  CONTACT: 'contact',
  TEXT: 'text',
  FILE_UPLOAD: 'file_upload',
  TOGGLE: 'toggle',
  DROPDOWN: 'dropdown',
  MULTIPLE_CHOICE: 'multiple_choice',
  ADDITIONAL_QUESTION: 'additional_question',
  CURRENCY: 'currency',
}

export const CUSTOM_FIELD_TYPES_OPTIONS = [
  { label: 'Contact', value: 'contact' },
  { label: 'Text', value: 'text' },
  { label: 'File Upload', value: 'file_upload' },
  { label: 'Yes / No Toggle', value: 'toggle' },
  { label: 'Dropdown', value: 'dropdown' },
  { label: 'Multiple Choice', value: 'multiple_choice' },
  { label: 'Additional Question', value: 'additional_question' },
  { label: 'Currency', value: 'currency' },
];

export const CUSTOM_TOGGLE_FIELD_TYPES = {
  TEXT: 'text',
  FILE_UPLOAD: 'file_upload'
}

export const CUSTOM_TOGGLE_FIELD_TYPES_OPTIONS = [
  { label: 'Text', value: 'text' },
  { label: 'File Upload', value: 'file_upload' },
  { label: 'Nothing', value: '' },
];

export const MANUAL_ACCOUNT_TYPES = [
  { label: 'Checking', value: 'checking' },
  { label: 'Savings', value: 'savings' },
  // { label: 'Credit Card', value: 'credit_card' },
  { label: 'Mortgage', value: 'mortgage' },
  { label: 'Loan', value: 'loan' },
  { label: 'Line Of Credit', value: 'line_of_credit' },
];

export const CREDIT_SAFE_COUNTRIES = [
  { label: 'Canada', value: 'CA' },
  { label: 'United States', value: 'US' },
  { label: 'Great Britain', value: 'GB' },
];

export const EXPERIAN_COUNTRIES = [
  { label: 'United States', value: 'US' },
];

export const DNB_COUNTRIES = [
  { label: 'Canada', value: 'CA' },
  { label: 'United States', value: 'US' }
];

interface IProvincesOrStates {
  [key: string]: ILabelValue[],
}

export const PROVINCES_OR_STATES: IProvincesOrStates = {
  CA: [
    { label: 'All', value: '' },
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland and Labrador', value: 'NL' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
  ],
  US: [
    { label: 'All', value: '' },
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
  ],
  GB: [],
};

export const ROLES = {
  CP: 'Credit Provider',
  CS: 'Credit Seeker',
  VENDOR: 'Vendor',
};

export const NAV_ITEMS = {
  APPLICATIONS: 'Applications',
  PENDING_APPROVAL: 'Pending Approval',
  IN_PROGRESS: 'In Progress',
  ARCHIVED: 'Archived',
  CUSTOMERS: 'Customers',
  PENDING_REVIEW: 'Pending Review',
  APPROVED: 'Approved',
  IMPORTED: 'Imported',
  APPLICANTS: 'My Applications',
  REFERENCES: 'References Requested',
  TEMPLATE: 'App Template',
  SETTINGS: 'Settings',
  LOGOUT: 'Logout',
};
//HELP: 'Help',

export const VR_STATUS = {
  SUCCESS: 'success',
  PENDING: 'pending',
  FAILURE: 'failure',
};

const LAST_YEAR = 2024;
const YEARS_NUMBER = 124;
export const YEARS_OPTIONS = Array.from(new Array(YEARS_NUMBER), (_, index) => (
  { label: `${LAST_YEAR - index}`, value: `${LAST_YEAR - index}` }
));

export const PLACES_LIBRARIES: "places"[] = ['places'];

export const PLACES_OPTIONS = {
  fields: ["address_components"],
};

export const ONBOARDING_PATH = {
  PROVIDER: '/signup/provider',
  // applicant-onboarding remove
  APPLICANT: '/signup/seeker',
  CHANGES_REQUESTED: '/signup/changes-requested',
  VENDOR: '/signup/vendor',
  USER: '/signup/user',
  SEEKER_SIGNATURE: '/signup/signature/seeker',
}

export const ADDITIONAL_QUESTION_DEFAULT_OPTION = { label: "Don't know / Not Listed", value: "Don't know / Not Listed" };

export const NO_WEBSITE_VALUE = 'N/A';

export const BANK_ACCOUNT_CATEGORY = {
  BUSINESS: 'business',
  PERSONAL: 'personal'
}

export const WARNINGS = {
  DOMAIN: 'Web-domain of this email indicates a personal and not business email address. Proceed with caution',
  SEE_ALL_BUTTON: 'Currently, only sections that require changes are displayed. Click here to view the full application.'
}

export const CHANGES_REQUESTED_SECTIONS = {
  COMPANY_INFO: 'company_info',
  COMPANY_CONTACTS: 'company_contacts',
  BANK_INFO: 'bank_info',
  VENDORS: 'vendors',
  OTHER_INFO: 'customizable',
}