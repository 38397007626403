import gql from 'graphql-tag';

export const GET_CHANGE_REQUEST = gql`
  query GetChangeRequest($changeRequestId: ID!) {
    getChangeRequest(changeRequestId: $changeRequestId) {
      id
      sections
      customer {
        id
        settings {
          autoBav
          requireBankInfo
        }
        latestApplication {
          creditLimit
          requestedCreditLimit
          einFederalTaxNumber
          creditTerms
          noBankInfo
        }
        providerCompany {
          id
          numTotalReferences
          numRequiredReferences
          agreementText
          creditTerms
          customFields {
            id
            type
            label
            description
            required
            allowMultipleUploads
            options
            contact {
              showName
              nameRequired
              showEmail
              emailRequired
              showPhone
              phoneRequired
              showExtension
              extensionRequired
            }
            yesToggle {
              type
              allowMultipleUploads
            }
            noToggle {
              type
              allowMultipleUploads
            }
          }
          settings {
            autoBavDefault
            attachCreditOptions
            seekerApprovalAlert
            seekerDenialAlert
            requireEinFederalTaxNumber
            requirePersonalGuaranteeDefault
            enableAddress
            requireAddress
            enableAddressFor
            enableSsnSinNumber
            requireSsnSinNumber
            enableSsnSinNumberFor
            enableDriverLicenseNo
            requireDriverLicenseNo
            enableDriverLicenseNoFor
            enableDriverLicenseState
            requireDriverLicenseState
            enableDriverLicenseStateFor
            enableDateOfBirth
            requireDateOfBirth
            enableDateOfBirthFor
            enableShippingAddress
            enableBillingAddress
            requireShippingAddress
            requireBillingAddress
          }
        }
        seekerCompany {
          id
          name
          type
          stakeholders {
            id
            name
            position
            email
            phoneNumber
            extension
            customFieldId
            ownershipPercentage
            isPrimaryContact
            isOwner
            address {
              address_line_1
              address_line_2
              city
              state
              country
              postal_code
            }
            ssnSinNumber
            driverLicenseNo
            driverLicenseState
            dob
            showNonBusinessEmailDomainWarning
          }
          address {
            address_line_1
            address_line_2
            city
            state
            country
            postal_code
          }
          website
          established
          phoneNumber
          description
          pendingVendorInvites {
            id
            toEmail
            toName
            toCompanyName
            toPosition
            toPhoneNumber
            sent
            bounced
            type
            showNonBusinessEmailDomainWarning
            updatedAt
            optOut
            optOutAt
          }
          manualInstitutions {
            id
            contactEmail
            contactName
            contactPhone
            contactTitle
            institutionAddress
            institutionName
            showNonBusinessEmailDomainWarning
            accounts {
              id
              isBusinessAccount
              firstname
              lastname
              accountNumber
              routingNumber
              accountType
              verificationStatus
              verificationMessage
              firstnameMatched
              lastnameMatched
              internalBankDocuments {
                id
                storedFile {
                  id
                  originalFilename
                  signedUrl
                }
                notes
              }
              insertedAt
              updatedAt
            }
            bankStatements {
              id
              path
              originalFilename
              signedUrl
            }
          }
        }
        customFieldAnswers {
          id
          text
          isToggled
          currency {
            currency
            amount
          }
          selections
          file {
            id
            originalFilename
            signedUrl
          }
          customField { id }
        }
      }
    }
  }
`;

