import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { RedirectContext, IRedirectContextValue } from './context';

interface IProps {
  children: React.ReactNode,
}

export const RedirectProvider = ({
  children,
}: IProps) => {
  const history = useHistory();
  const { search: currentSearch } = useLocation();

  const redirects: IRedirectContextValue = {
    toCustomerPage: (customerId, search = currentSearch) => history.push({ pathname: `/dashboard/customer/${customerId}`, search }),
    toApplicationPage: (customerId, search = currentSearch) => history.push({ pathname: `/dashboard/requests/${customerId}`, search }),
    toSeekerSignup: ({ companyId, step = '' }, search = currentSearch) => history.push({ pathname: `/signup/seeker/${companyId}/${step}`, search }),
    toLogin: (redirect) => history.push({ pathname: '/login', search: redirect ? `?redirect=${redirect}` : '' }),
    toDashboard: () => history.push('/dashboard'),
    toCPDashboard: () => history.push('/dashboard/customers'),
    toCSDashboard: () => history.push('/dashboard/requests'),
    toVRDashboard: () => history.push('/dashboard/references'),
  };

  return (
    <RedirectContext.Provider
      value={redirects}
    >
      {children}
    </RedirectContext.Provider>
  );
};
